///////***** PAGE ACCUEIL*****/////// 

//**header**// 
body{
  width: 100%;
  padding: 0;
  margin: 0;

}
main{
  width: 100%;
  padding: 0;
  margin: 0;
}

header{
    width: 99vw;
    height: 100vh;
    background-image: url('new_header.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: lightgray;
    padding: 0;
    z-index: 10000000;

  }
   nav.header {
        width: 99vw;
        background-color: #25346C;
        overflow: hidden;
        position: fixed;
        top: 0;

        & a {
            display: block;
            float: left;
            padding: 24px 0px;
            color: white;
            text-decoration: none;
            font-size: 24px;
            width: 33.3%;
            text-align: center;
            font-family: Quicksand,sans-serif;
            font-weight: 500;
            transition: 0.4s;
        }

            & a:hover {
                background-color: #5677BA;
                
}
          
      }

//**reel**// 
section.reels{
    height: 480px;
    margin: 0;
    padding: 0;
    margin-top: 64px;

    & h2{
      color: #5677BA;
      text-align: center;
      font-family: Quicksand,sans-serif;
      font-weight: 600;
      font-size: 32px;
      margin: 0;
      padding-top: 24px;
      
    }

    & div.item{
      display: flex;
      height: auto;
      padding-top: 32px;


      & div.reel{
        background-color: lightgray;
        height: 540px;
        width: 960px;
        margin: auto;
        margin-top: 0px;
        margin-bottom: 0px;
      }

    }
    
}

//**projet**// 
section.projets{
    height: auto;
    margin: 0;
    padding: 0;
    margin-top: 64px;
    background-color: #EDF0F6;

    & h2{
      color: #5677BA;
      text-align: center;
      font-family: Quicksand,sans-serif;
      font-weight: 600;
      font-size: 32px;
      margin: 0;
      padding-top: 24px;
      
    }

    & div.item{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: auto;
      padding-bottom: 48px;
      

      & article.zone{

        display: flex;
        flex-direction: column;
        height: auto;
        width: 50%;
        margin-top: 48px;

        & div.projet{
          background-color: #25346C;
          height: 360px;
          width: 640px;
          margin: auto;
          margin-top: 0px;
          margin-bottom: 0px;

          & img{
            width: 100%;
            height: auto;
          }

        }

        & h3{
          color: #25346C;
          margin: auto;
          margin-top: 12px;
          margin-bottom: 12px;
          font-family: Quicksand,sans-serif;
          font-weight: 500;
          text-align: center;
          font-size: 24px;

          & a{
            text-decoration: none;
            color: #25346C;
          }
      }
    }
  }
}


//**footer**// 
footer{
  height: auto;
  background-color: white;
  text-align: center;

  & h2 {

      color: #5677BA;
      text-align: center;
      font-family: Quicksand,sans-serif;
      font-weight: 600;
      font-size: 32px;
      margin: 0;
      padding-top: 24px;
  }

  & nav.footer{
    width: 99%;
    background-color: white;
    bottom: 0;
    margin-top: 32px;

    & a {
        display: block;
        float: left;
        padding: 12px 0px;
        color: white;
        text-decoration: none;
        font-size: 16px;
        width: 33%;
        text-align: center;
        font-family: Quicksand,sans-serif;
        font-weight: 500;
        color: #5677BA;
        transition: 0.4s;
    }
  }
}

////***point de rupture1 320px***////
@media screen and (min-width:320px){

//**header**// 
header{
  margin-top: 72px;
  width: 100vw;
  height: 320px;
  background-image: url('new_header.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;

  & h1.nom{
    position: relative;
    top: 3%;
    color: white;
    text-align: center;
    font-family: Quicksand,sans-serif;
    font-weight: 700;
    font-size: 24px;
  }

  & h1.profil{
    position: relative;
    top: 73%;
    color: white;
    text-align: center;
    font-family: Quicksand,sans-serif;
    font-weight: 700;
    font-size: 24px;
  } 
}

  nav.header{
    
    width: 100%;
    z-index: 1000000;

    & a {
      width: 33,33%;
      font-size: 18px;
      font-weight: 500;
    }
    
  }
  

//**reel**// 
section.reels{
  height: 144px;
  margin: 0;
  padding-bottom: 64px;
  margin-top: 12px;
  
  & h2{
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    padding-top: 12px;
  }
  
  & div.item{
    display: flex;
    height: auto;
    padding-top: 12px;
    
    
    & div.reel{
      height: 144px;
      width: 256px;
      margin: auto;
      margin-top: 0px;
      margin-bottom: 0px;

      & iframe{
      height: 144px;
      width: 256px;
      margin: auto;
      margin-top: 0px;
      margin-bottom: 0px;
      }
    }
    
  }
  
}

//**projet**// 
section.projets{
  height: auto;
  margin: 0;
  padding: 0;
  margin-top: 24px;
  background-color: #EDF0F6;
  
  & h2{
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    padding-top: 12px;
  }
  
  & div.item{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    
    
    & article.zone{
      display: flex;
      flex-direction: column;
      height: auto;
      width: auto;
      margin: auto;
      margin-top: 24px;
      
      & div.projet{
        background-color: lightgray;
        height: 144px;
        width: 256px;
        margin: auto;
        margin-top: 0px;
        margin-bottom: 0px;
        
      }
      
      & h3{
        font-size: 18px;
        color: #25346C;
        margin: auto;
        margin-top: 12px;
        margin-bottom: 6px;
        font-family: Quicksand,sans-serif;
        font-weight: 600;
        text-align: center;
        
        & a{
          text-decoration: none;
          color: #25346C;
        }
        
        & a:hover {
          color: #5677BA;
          
        }
      }
    }
  }
}

//**footer**// 
footer{
  width: 100vw;
  background-color: #25346C;

  & h2{
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    padding-top: 12px;
  }

  & nav.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    background-color: #25346C;

    & a {
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        color: white;
    }

    & a:hover {
      color: #5677BA;
      
    }
  }
}

}
////***point de rupture2 768px***////
@media screen and (min-width:768px){

  //**header**// 
  header{
    margin-top: 72px;
    width: 100vw;
    height: 30vh;
    background-image: url('new_header.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;

    & h1.nom{
      position: relative;
      top: 6%;
      color: white;
      text-align: center;
      font-family: Quicksand,sans-serif;
      font-weight: 700;
      font-size: 28px;
    }

    & h1.profil{
      position: relative;
      top: 72%;
      color: white;
      text-align: center;
      font-family: Quicksand,sans-serif;
      font-weight: 700;
      font-size: 28px;
    }
  }
  
   nav.header{
      
      width: 100%;
      z-index: 1000000;
  
      & a {
        width: 33,33%;
        font-size: 18px;
        font-weight: 500;
      }
    }
    
  //**reel**// 
  section.reels{
    height: 360px;
    padding-bottom: 90px;
    
    & h2{
      font-weight: 600;
      font-size: 24px;
      margin: 0;
      padding-top: 12px;
    }
    
    & div.item{
      display: flex;
      height: auto;
      padding-top: 12px;
      
      
      & div.reel{
        height: 360px;
        width: 640px;

        & iframe{
          height: 360px;
          width: 640px;
          margin: auto;
          margin-top: 0px;
          margin-bottom: 0px;
          }

      }
      
    }
    
  }

  //**projet**// 
  section.projets{

    & h2{
      font-size: 24px;
      font-weight: 600;
    }

    & div.item{

      & article.zone{
 
        & div.projet{
          width: 426px;
          height: 240px;
        }

        & h3{
          font-size: 18px;
          font-weight: 600;
        }
        
      }
    }
  }

  footer {
    background-color: #25346C;

    & nav.footer{
      background-color: #25346C;
      
      
      & a{
        color: white;
      }
      
      & a:hover {
        color: #5677BA;
        transition: 0.4s;
      }
    }
  }
  }

////***point de rupture3 1024px***////
@media screen and (min-width:1024px){

  //**header**// 
  header{
    margin-top: 74px;
    margin-right: 0px;
    margin-left: 0px;
    width: 100vw;
    height: 90vh;
    background-image: url('new_header.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;

    & h1.nom{
      position: relative;
      top: 6%;
      color: white;
      text-align: center;
      font-family: Quicksand,sans-serif;
      font-weight: 700;
      font-size: 48px;
    }

    & h1.profil{
      position: relative;
      top: 72%;
      color: white;
      text-align: center;
      font-family: Quicksand,sans-serif;
      font-weight: 700;
      font-size: 48px;
    }
  }

    nav.header{
      z-index: 1000000;
      width: 100vw;

      & a {
        font-size: 21px;
        font-weight: 500;
      }

    }

  //**reel**// 
  section.reels{
    height: 576px;
    width: 1024px;
    margin: auto;
    padding-bottom: 120px;
    
    & h2{
      font-size: 32px;
      padding-top: 24px;
      font-weight: 600;
    }
    
    & div.item{
      display: flex;
      height: auto;
      padding-top: 32px;
      
      & div.reel{
        height: 576px;
        width: 1024px;

        & iframe{
          height: 576px;
          width: 1024px;
          margin: auto;
          margin-top: 0px;
          margin-bottom: 0px;
          }
      }
    }
  }

  //**projet**// 
  section.projets{
    margin-top: 48px;

    & h2{
      font-size: 32px;
      padding-top: 24px;
      font-weight: 600;
    }

    & div.item{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: auto;
      padding-bottom: 48px;

      & article.zone{
        display: flex;
        flex-direction: column;
        height: auto;
        width: 50%;
        margin-top: 48px;

        & div.projet{
          overflow: hidden;
          width: 640px;
          height: 360px;
          background-color: #5677BA;

          & img{
            transition: all .4s ease;
            -webkit-transition: all .4s ease;
            -moz-transition: all .4s ease;
            -ms-transition: all .4s ease;
            -o-transition: all .4s ease;
}
          
          & img:hover{
            opacity: .5;
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
          }
        }

        & h3{
          font-size: 24px;
          font-weight: 600;
        }
        
      }
    }
  }

  //**footer**// 
  footer{
    height: auto;
    background-color: #25346C;
    text-align: center;
  
    & h2 {
  
      color: #5677BA;
        text-align: center;
        font-family: Quicksand,sans-serif;
        font-weight: 600;
        font-size: 32px;
        margin: 0;
        padding-top: 24px;
    }
  
    & nav.footer{
      width: 100%;
      background-color: #25346C;
      bottom: 0;
      margin-top: 32px;
      display: flex;
      flex-direction: row;
  
      & a {
          display: block;
          float: left;
          padding: 12px 0px;
          color: white;
          text-decoration: none;
          font-size: 21px;
          width: 33.33%;
          text-align: center;
          font-family: Quicksand,sans-serif;
          font-weight: 500;
      }
  
      & a:hover {
        color: #5677BA;
      }
    }
  }
  
}

///////***** PAGE PROJET*****/////// 
div.page-projet{
  margin: 75px auto 0;
  width: 80vw;
  height: auto;

  & h2{
      color: #5677BA;
      text-align: center;
      font-family: Quicksand,sans-serif;
      font-weight: 600;
      font-size: 32px;
      padding-top: 48px;
      padding-bottom: 24px;
  }

    & div.carousel{
      margin: auto;
      width: 62vw;
      height: auto;
      z-index: 1;
    }

  }

  @media screen and (min-width:320px){
    div.page-projet{
 
     & h2{
       font-weight: 600;
       font-size: 24px;
       padding-top: 36px;
       padding-bottom: 12px;
       }

       & div.info{
        display: flex;
        flex-direction: column;

        & div.logiciel{
          width: 100%;
          height: auto;

          & h3{
            font-size: 18px;
            color: #25346C;
            margin: auto;
            margin-top: 32px;
            font-family: Quicksand,sans-serif;
            font-weight: 700;
            text-align: center;
          }
          
          & ul{
            list-style: none;
            padding: 0;
            margin-top: 12px;


            & li{
              color:#5677BA;
              font-size: 16px;
              text-align: center;
            }
          }

        }
        & div.desc{
          width: 100%;
          height: auto;

          & h3{
            font-size: 18px;
            color: #25346C;
            margin: auto;
            margin-top: 24px;
            margin-bottom: 6px;
            font-family: Quicksand,sans-serif;
            font-weight: 700;
            text-align: center;
          }
          
          & p{
              color: #25346C;
              font-size: 16px;
              text-align: center;
          }
        }

      div.retour{
        display: flex;
        flex-direction: column;
        height: 21px;
        width: 144px;
        margin: 0 auto;
        background-color: #5677BA;
        text-align: center;
        padding: 10px;
        border-radius: 12px;
        margin-bottom: 32px;
        margin-top: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
      }  
        div.retour > a{
            font-family: Quicksand,sans-serif;
            font-weight: 500;
            color:white;
            text-decoration: none;
            font-size: 12px;
            margin: auto;
        }
      }
     }
   }

  @media screen and (min-width:768px){
    div.page-projet{
  
      & h2{
        font-weight: 600;
        font-size: 24px;
        padding-top: 36px;
        padding-bottom: 12px;
        }

        
      }
    }

  @media screen and (min-width:1024px){

  div.page-projet{

    & h2{
      color: #5677BA;
      text-align: center;
      font-family: Quicksand,sans-serif;
      font-weight: 600;
      font-size: 32px;
      padding-top: 96px;
      padding-bottom: 24px;
      }

      & div.info{
        display: flex;
        flex-direction: column;

        & div.logiciel{
          width: 100%;
          height: auto;

          & h3{
            font-size: 24px;
            color: #25346C;
            margin: auto;
            margin-top: 24px;
            font-family: Quicksand,sans-serif;
            font-weight: 600;
            text-align: center;
          }
          
          & ul{
            list-style: none;
            padding: 0;

            & li{
              color:#5677BA;
              font-size: 21px;
              text-align: center;
            }
          }

        }
        & div.desc{
          width: 100%;
          height: auto;

          & h3{
            font-size: 24px;
            color: #25346C;
            margin: auto;
            margin-top: 24px;
            font-family: Quicksand,sans-serif;
            font-weight: 600;
            text-align: center;
          }
          
          & p{
              color: #25346C;
              font-size: 21px;
              text-align: center;
          }

        }

        div.retour{
          display: flex;
          flex-direction: column;
          height: 32px;
          width: 272px;
          margin: 0 auto;
          background-color: #5677BA;
          text-align: center;
          padding: 10px;
          border-radius: 12px;
          margin-bottom: 48px;
          margin-top: 32px;
          -webkit-border-radius: 12px;
          -moz-border-radius: 12px;
          -ms-border-radius: 12px;
          -o-border-radius: 12px;
          transition: 0.4s;

          & a{
              font-family: Quicksand,sans-serif;
              font-weight: 500;
              color:white;
              text-decoration: none;
              font-size: 18px;
              margin: auto;
          }
        }
        
        div.retour:hover{
          background-color: #25346C;
        }
          

      }
    }
  }


